<template>
  <v-dialog v-model="isDialogVisible" max-width="600px" height="400px" persistent>
    <!-- Dialog Activator -->
    <template #activator="{ on, attrs }">
      <v-btn size="small" icon v-bind="attrs" v-on="on">
        <v-icon size="18">
          {{ icons.mdiCancel }}
        </v-icon>
      </v-btn>
    </template>

    <!-- Dialog Content -->
    <v-card title="Terminate Medical Card">
      <v-card-text>
        <p>Medical Card Number: <b>{{ cardNumber }}</b> will be terminated permanently.</p>
        <p><b>Are you sure?</b></p>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="error" outlined @click="closeDialog" :disabled="loading">Close</v-btn>
        <v-btn color="success" @click="handleSubmit" :disabled="loading" :loading="loading">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cardService } from '../../services'

import { mdiPlus, mdiCancel } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    cardId: {
      type: String,
      required: true,
    },
    cardNumber: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isDialogVisible = ref(false)
    const loading = ref(false)
    const error = ref(null)

    const resetForm = () => {
      error.value = null
    }

    const closeDialog = () => {
      resetForm()

      isDialogVisible.value = false
    }

    const handleSubmit = async () => {
      loading.value = true

      try {
        const { status } = await cardService.terminate(props.cardId)

        if (status === 'success') {
          resetForm()
        }
      } catch ({ response }) {
        if (response) {
          if (response.status === 422) {
            error.value = response.data.data
          }
        }
      } finally {
        loading.value = false
      }
    }

    return {
      isDialogVisible,
      loading,

      icons: {
        mdiPlus,
        mdiCancel,
      },

      closeDialog,
      handleSubmit,
    }
  },
}
</script>
