<template>
  <div>
    <v-card>
      <!-- table -->
      <v-data-table
        :headers="columns"
        :items="items"
        :options.sync="options"
        :server-items-length="itemsTotal"
        :loading="loading"
        class="text-no-wrap"
      >
        <!-- main -->
        <template #[`item.main`]="{ item }">
          <span class="text-no-wrap">{{ item.main ? 'Yes' : 'No' }}</span>
        </template>

        <!-- register date -->
        <template #[`item.registered_at`]="{ item }">
          <span class="text-no-wrap">{{ dayjs(item.registered_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>

        <!-- activate date -->
        <template #[`item.activated_at`]="{ item }">
          <span class="text-no-wrap">{{ dayjs(item.activated_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>

        <!-- expiry date -->
        <template #[`item.expired_at`]="{ item }">
          <span class="text-no-wrap">{{ dayjs(item.expired_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>

        <!-- terminate date -->
        <template #[`item.terminated_at`]="{ item }">
          <span class="text-no-wrap">{{ item.terminated_at ? dayjs(item.terminated_at).format('YYYY-MM-DD HH:mm:ss'): '-' }}</span>
        </template>

        <!-- main -->
        <template #[`item.in_grace_period`]="{ item }">
          <span class="text-no-wrap">{{ item.in_grace_period ? 'Yes' : 'No' }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex gap-1">
            <v-btn
              size="small"
              icon
              @click="setAside(item)"
            >
              <v-icon size="18">{{ icons.mdiNoteEdit }}</v-icon>
            </v-btn>
            <terminate-dialog
              :card-id="item.id"
              :card-number="item.card_number"
            />
          </div>
        </template>
      </v-data-table>
    </v-card>

    <form-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="
        paginate();
        aside = false
      "
    />
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { mdiNoteEdit, mdiDotsVertical, mdiCancel } from '@mdi/js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ref } from '@vue/composition-api'
import FormAside from './FormAside'
import TerminateDialog from './TerminateDialog.vue'

dayjs.extend(utc)

export default {
  components: {
    FormAside,
    TerminateDialog,
  },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { loading, options, paginate, items, itemsTotal, headerprops, loadResource, resource } = props.tableList

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'Card Number', value: 'card_number' },
      { text: 'Main User', value: 'main' },
      { text: 'First Name', value: 'first_name' },
      { text: 'Last Name', value: 'last_name' },
      { text: 'Gender', value: 'gender' },
      { text: 'Birth Date', value: 'birth_date' },
      { text: 'Phone', value: 'phone' },
      { text: 'Relationship', value: 'relationship' },
      { text: 'Register Date', value: 'registered_at' },
      { text: 'Activate Date', value: 'activated_at' },
      { text: 'Expiry Date', value: 'expired_at' },
      { text: 'Status', value: 'status' },
      { text: 'Terminate Date', value: 'terminated_at' },
      { text: 'In Grace Period?', value: 'in_grace_period' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const aside = ref(false)

    const setAside = item => {
      aside.value = true
      resource.value = item
    }

    return {
      t,
      dayjs,
      formatDate,

      resource,
      loadResource,

      // Table config
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,

      columns,

      setAside,
      aside,
      resource,

      icons: {
        mdiDotsVertical,
        mdiNoteEdit,
        mdiCancel,
      },
    }
  },
}
</script>
